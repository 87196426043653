import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Pagination from "react-bootstrap/Pagination";

export default function Active() {
  const [activeToggle, setActiveToggle] = useState("images"); // Initially set 'images' as active
  const navigate = useNavigate();
  return (
    <Layout>
      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <div className="image-heading-text">
              <h2>Active Fleet Owner</h2>
            </div>
            <div className="search-tab ">
              <div className="input-search full-input-width">
                <Form>
                  <div className="search-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M19 19L13 13"
                        stroke="#2F2B3D"
                        stroke-opacity="0.9"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <circle
                        cx="8"
                        cy="8"
                        r="7"
                        stroke="#2F2B3D"
                        stroke-opacity="0.9"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <Form.Group controlId="formBasicPassword">
                    <Form.Control type="text" placeholder="Search " />
                  </Form.Group>
                </Form>
              </div>
            </div>
            <div className="user-search-passengers">
              <div className="drop-down">
                <div className="toggle-box-top">
                  {/* First toggle (Images) */}
                  <div
                    className={`toggle-btn ${
                      activeToggle === "images" ? "toggle-active" : ""
                    }`}
                    onClick={() => setActiveToggle("images")} // Set active state to 'images' on click
                  >
                    <p>Active</p>
                  </div>

                  {/* Second toggle (HEIC To JPG) */}
                  <div
                    className={`toggle-btn ${
                      activeToggle === "heicToJpg" ? "toggle-active" : ""
                    }`}
                    onClick={() => setActiveToggle("heicToJpg")} // Set active state to 'heicToJpg' on click
                  >
                    <p>Blocked</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>S.no.</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone no.</th>
                    <th>No. of vechicles</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>01</td>
                    <td>
                      <div className="first-user">
                        <div
                          className="user-profile"
                          onClick={() => navigate("/user-over-view")}
                        >
                          <img
                            src={require("../Assets/Images/user.svg").default}
                          />
                          <div className="user-id">
                            <p>Brenda</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>brenda123@gmail.com</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>+91 98765 45321</p>
                      </div>
                    </td>

                    <td>
                      <div className="user-id">
                        <p>02</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-view">
                        <img
                          src={
                            require("../Assets/Images/view-icon.svg").default
                          }
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>
                      <div className="first-user">
                        <div
                          className="user-profile"
                          onClick={() => navigate("/user-over-view")}
                        >
                          <img
                            src={require("../Assets/Images/user.svg").default}
                          />
                          <div className="user-id">
                            <p>Brenda</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>brenda123@gmail.com</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>+91 98765 45321</p>
                      </div>
                    </td>

                    <td>
                      <div className="user-id">
                        <p>02</p>
                      </div>
                    </td>
                    <td>
                      <div
                        className="user-view"
                      >
                        <img
                          src={
                            require("../Assets/Images/view-icon.svg").default
                          }
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>
                      <div className="first-user">
                        <div
                          className="user-profile"
                          onClick={() => navigate("/user-over-view")}
                        >
                          <img
                            src={require("../Assets/Images/user.svg").default}
                          />
                          <div className="user-id">
                            <p>Brenda</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>brenda123@gmail.com</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>+91 98765 45321</p>
                      </div>
                    </td>

                    <td>
                      <div className="user-id">
                        <p>02</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-view">
                        <img
                          src={
                            require("../Assets/Images/view-icon.svg").default
                          }
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>
                      <div className="first-user">
                        <div
                          className="user-profile"
                          onClick={() => navigate("/user-over-view")}
                        >
                          <img
                            src={require("../Assets/Images/user.svg").default}
                          />
                          <div className="user-id">
                            <p>Brenda</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>brenda123@gmail.com</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>+91 98765 45321</p>
                      </div>
                    </td>

                    <td>
                      <div className="user-id">
                        <p>02</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-view">
                        <img
                          src={
                            require("../Assets/Images/view-icon.svg").default
                          }
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>
                      <div className="first-user">
                        <div
                          className="user-profile"
                          onClick={() => navigate("/user-over-view")}
                        >
                          <img
                            src={require("../Assets/Images/user.svg").default}
                          />
                          <div className="user-id">
                            <p>Brenda</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>brenda123@gmail.com</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>+91 98765 45321</p>
                      </div>
                    </td>

                    <td>
                      <div className="user-id">
                        <p>02</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-view">
                        <img
                          src={
                            require("../Assets/Images/view-icon.svg").default
                          }
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>
                      <div className="first-user">
                        <div
                          className="user-profile"
                          onClick={() => navigate("/user-over-view")}
                        >
                          <img
                            src={require("../Assets/Images/user.svg").default}
                          />
                          <div className="user-id">
                            <p>Brenda</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>brenda123@gmail.com</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>+91 98765 45321</p>
                      </div>
                    </td>

                    <td>
                      <div className="user-id">
                        <p>02</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-view">
                        <img
                          src={
                            require("../Assets/Images/view-icon.svg").default
                          }
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <div className="pagination-section">
                <div className="showing-user">
                  <p>Showing 1 to 10 of 50 entries</p>
                </div>
                <div className="pagination-block">
                  <Pagination>
                    <Pagination.First />
                    <Pagination.Prev />
                    <Pagination.Item>{1}</Pagination.Item>
                    <Pagination.Item>{2}</Pagination.Item>
                    <Pagination.Item active>{3}</Pagination.Item>
                    <Pagination.Item>{4}</Pagination.Item>
                    <Pagination.Item>{5}</Pagination.Item>
                    <Pagination.Next />
                    <Pagination.Last />
                  </Pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
}

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import Logo from "../Assets/Images/VibeOnn_Placeholder.png";
import { useDispatch, useSelector } from "react-redux";
import {
  FleetOwnerManagement,
  fleetownerRequests,
} from "../Redux/Actions/FleetOwnerAction";
import PaginationComponet from "../Components/Layout/Pagination";



export default function FleetOwnerRequest() {
  const fleetdata = useSelector((state) => state.fleet?.alldata);
  console.log(fleetdata);
  const [activeToggle, setActiveToggle] = useState("images");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const url = process.env.REACT_APP_FILE_BASE_URL;

  const [showd, setshowd] = useState(false);
  const [page, setpage] = useState(0);
  const [limit, setlimit] = useState(10);

  const [flag, setflag] = useState(true);
  const [userid, setUserid] = useState("");
  const [curruntImage, setCurruntImage] = useState("");
  const [admindetails, setAdminDetails] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [activePopoverId, setActivePopoverId] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [FleetRequest, setfleetrequest] = useState([]);
  const [Pagination, setpagination] = useState();


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    dispatch(
      fleetownerRequests({
        page: pageNumber,
        limit: limit,
      })
    );
  };

  useEffect(() => {
    const searchData = {
      page: page,
      limit: limit,
      search: searchValue,
    };
    dispatch(FleetOwnerManagement(searchData));
  }, [page, limit, searchValue, flag, dispatch]);




  useEffect(() => {
    const searchData = {
      page: page,
      limit: limit,
      search: searchValue,
    };
    dispatch(fleetownerRequests(searchData))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          setfleetrequest(res?.payload?.data?.data?.fleets);
          setpagination(res?.payload?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err, "Error");
      });
  }, [page, limit, searchValue, flag, dispatch]);

  return (
    <Layout>
      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <div className="image-heading-text">
              <h2>Fleet Owner Requests</h2>
            </div>
            <div className="search-tab ">
              <div className="input-search full-input-width">
                <Form>
                  <div className="search-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M19 19L13 13"
                        stroke="#2F2B3D"
                        stroke-opacity="0.9"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <circle
                        cx="8"
                        cy="8"
                        r="7"
                        stroke="#2F2B3D"
                        stroke-opacity="0.9"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <Form.Group controlId="formBasicPassword">
                    <Form.Control
                      type="text"
                      placeholder="Search "
                      name="searchvalue"
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                  </Form.Group>
                </Form>
              </div>
            </div>

            <div class="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>S.no.</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone no.</th>
                    <th>No. of vehicles</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {FleetRequest?.map((res, index) => {
                const serialNumber = (currentPage -1 ) * limit + index + 1;

                    return (
                      <tr>
                        <td>{serialNumber}</td>
                        <td>
                          <div className="first-user">
                            <div
                              className="user-profile"
                              // onClick={() => navigate("/user-over-view")}
                            >
                              <img
                                 src={
                                  res?.profile_image
                                    ? `${url}${res?.profile_image}`
                                    : Logo
                                }
                              />
                              <div className="user-id">
                                <p>{res?.company_name || "N/A"}</p>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="user-id">
                            <p>{res?.company_email || "N/A"}</p>
                          </div>
                        </td>
                        <td>
                          <div className="user-id">
                            <p>
                              {res?.country_code
                                ? `${res?.country_code} ${res?.phone_number}`
                                : "N/A"}
                            </p>
                          </div>
                        </td>

                        <td>
                          <div className="user-id">
                            <p>{res?.totalVehicle || "N/A"}</p>
                          </div>
                        </td>
                        <td>
                          <div className="user-view">
                            <img
                              src={
                                require("../Assets/Images/options-icon.svg")
                                  .default
                              }
                            />
                            <img
                              src={
                                require("../Assets/Images/view-icon.svg")
                                  .default
                              }
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="pagination-section">
                <div className="showing-user">
                  <p> Showing {1 + (currentPage - 1) * limit} -{" "}
                    {Math.min(currentPage * limit, Pagination?.totalCount)} of{" "}
                    {Pagination?.totalCount} results</p>
                </div>
                <div className="pagination-block">
          
                  <PaginationComponet
                    currentPage={currentPage}
                    totalCount={Pagination?.totalCount}
                    limit={Pagination?.totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
}

import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminAPI } from "../../Services/AdminApi";

// ==============  Driver Listing  =============

export const drivermanagement = createAsyncThunk(
  "drivermanagement",
  async (details) => {
    let url = `/newDriverRequest?page=${details?.page}&limit=${details?.limit}`;
    if (details.search) {
      url += `&name=${details.search}`;
    }
    const data = await AdminAPI.get(url);
    console.log(data);
    return data;
  }
);

// get request driver details

export const getRequestdriverDetails = createAsyncThunk(
  "getRequestdriverDetails",
  async (details) => {
    const data = await AdminAPI.get(`/driverDetails?userId=${details?.userId}`);
    console.log(data);

    return data;
  }
);


export const DriverApproveDecline = createAsyncThunk(
  "DriverApproveDecline",
  async (details) => {
    const data = await AdminAPI.patch(`/verifyDriver`,details);
    console.log(data);

    return data;
  }
);



// ============== Active Driver Listing  =============

export const ActiveDriverlist = createAsyncThunk(
  "ActiveDriverlist",
  async (details) => {
    let url = `/driverManagement?page=${details?.page}&limit=${details?.limit}`;
    if (details.search) {
      url += `&name=${details.search}`;
    }
    const data = await AdminAPI.get(url);
    console.log(data);
    return data;
  }
);
